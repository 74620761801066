import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterJAFZA(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - AUS Business Council' />
      <RegistrationFrom source='aus-bc' page='aus-bc' imageName='ausbg-registration' imageAlt='ausbg-registration' />
    </Layout>
  );
}
